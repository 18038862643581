import "./Nav.css";
import { useNavigate } from 'react-router-dom'
import { useState} from "react";
import { wait } from "../../utils";
import download from "./download.svg"

function Nav() {

  const navigate = useNavigate()

  const [navVisible, setNavVisible] = useState(false)
  
  async function goTo(path) {
    await wait(100)
    setNavVisible(false)
    navigate(path)
  }

    return (
    <nav>
      <div className="job-title">Full Stack Developer<br></br><a rel="noreferrer" target="_blank" href="./Luc CV Jan 2023.pdf"><span className="cv-link">Click to view CV pdf<img className="icon" alt="download icon" src={download}/>
</span></a></div>

      <div className="dropdown" onMouseLeave={() => {setNavVisible(false)}}>

      {navVisible && <div className="nav-container visible" onMouseOver={() => {setNavVisible(true)}} >
          <div className="menu-icon">
            <div className="menu-icon-element"></div>
            <div className="menu-icon-element"></div>
            <div className="menu-icon-element"></div>
          </div>
          <div className="nav-label">Navigation</div>
        </div>}
        {!navVisible && <div className="nav-container" onMouseOver={() => {setNavVisible(true)}} >
          <div className="menu-icon">
            <div className="menu-icon-element"></div>
            <div className="menu-icon-element"></div>
            <div className="menu-icon-element"></div>
          </div>
          <div className="nav-label">Navigation</div>
        </div>}
        {navVisible && (<div className="nav-content" >
          <div className="nav-item" onClick={() => goTo('/about-me')}>About me</div>
          <div className="nav-item" onClick={() => goTo('/portfolio')}>Portfolio</div>
          <div className="nav-item" onClick={() => goTo('/contact')}>Contact</div>
        </div>)}
        
      </div>

    </nav>
  );
}

export default Nav;
