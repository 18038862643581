import "./Header.css";
import github from "./github2.png";
import linkedin from "./linkedin.png";
import mail from "../Contact/mail.svg";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header>
      <Link to="/" className="name-link">
        <div id="name">Luc Cook</div>
      </Link>
      <div className="links-container">
      <a
        target={"_blank"}
        rel="noreferrer"
        href={"mailto:luc_cook@hotmail.co.uk"}
      >
        <img className="social" id="mail" alt="email" src={mail} />
      </a>
      <a target={"_blank"} rel="noreferrer" href={"https://github.com/LucCook"}>
        <img className="social" id="github" alt="github logo" src={github} />
      </a>
      <a
        target={"_blank"}
        rel="noreferrer"
        href={"https://www.linkedin.com/in/luc-cook-a72262153/"}
      > 
        <img
          id="linkedin"
          className="social"
          alt="linkedIn logo"
          src={linkedin}
        />
      </a>
      </div>
      
      
    </header>
  );
}

export default Header;
