import TechItem from "../TechItem/TechItem";

function PortfolioCard({ imgUrl, title, tech, githubRepo, hostedUrl, notes, setFullImg, setFullImgUrl}) {
  
  return (
    <div className="portfolio-card">
      <div className="project-title">{title}</div>
      <div className="links">
        <a href={githubRepo} target="_blank" rel="noreferrer"><div className="link">Github Repo</div></a>
        {hostedUrl && <a href={hostedUrl} target="_blank" rel="noreferrer"><div className="link">Hosted App</div></a>}
      </div>
      {/* <input type="checkbox" id={title} className="zoom-toggle" checked={zoom} onChange={() => {
        setZoom(!zoom)
      }}/> */}
      <label onClick={() => {
        setFullImg(true)
        setFullImgUrl(imgUrl)
      }}>
      <img className="project-img-preview" src={imgUrl} alt={title}/>
      <div className="img-label">click to toggle image size</div>
      </label>
      
      <div className="tech-list">
        {tech.map((item) => {
          return <TechItem item={item} key={item}/>;
        })}
      </div>
      
      <div className="notes">
        {notes && <ul>
            {notes.map((note) => <li>{note}</li>)}
          </ul>}
      </div>
    </div>
  );
}

export default PortfolioCard;
