import { wait } from "../../utils";
import { useNavigate, useLocation } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const {pathname} = useLocation()
  
  async function goTo(path) {
    await wait(100);
    navigate(path);
  }

  console.log(pathname)

  return (
    <footer>
      <div className="button-container">
        {!(pathname === '/about-me' | pathname === "/") && <div className="button" onClick={() => goTo("/about-me")}>View my profile</div>}
        
        {pathname !== '/portfolio' && <div className="button" onClick={() => goTo("/portfolio")}>
          View my portfolio
        </div>}
        {pathname !== '/contact' && <div className="button" onClick={() => goTo("/contact")}>Contact details</div>}
      </div>
    </footer>
  );
}

export default Footer;
