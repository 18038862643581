import github from "../Header/github2.png";
import linkedin from "../Header/linkedin.png";
import mail from "./mail.svg"
import "./Contact.css";

function Contact() {
  return (
    <main>
      <div className="contact-container">
        <a
          target={"_blank"}
          rel="noreferrer"
          href={"https://github.com/LucCook"}
        >
          <div className="contact-item github">
            <img
              className="social-full"
              id="github-full"
              alt="github logo"
              src={github}
            />
            <div>Github - LucCook</div>
          </div>
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={"https://www.linkedin.com/in/luc-cook-a72262153/"}
        >
          <div className="contact-item linkedin">
            <img
              className="social-full"
              id="linkedin-full"
              alt="linkedin logo"
              src={linkedin}
            />
            <div>LinkedIn - Luc Cook</div>
          </div>
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href={"mailto:luc_cook@hotmail.co.uk"}
        >
          <div className="contact-item mail">
            <img
              className="social-full"
              id="mail-full"
              alt="email"
              src={mail}
              width={100}
            />
            <div>Email - luc_cook@hotmail.co.uk</div>
          </div>
        </a>
      </div>
    </main>
  );
}

export default Contact;
