import "./App.css";
import AutoScroll from "./components/AutoScroll/AutoScroll";
import Header from "./components/Header/Header.jsx";
import Nav from "./components/Nav/Nav";
import Profile from "./components/Profile/Profile";
import Footer from "./components/Footer/Footer";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const [content, setContent] = useState("home");

  return (
    <BrowserRouter>
      <div className="App">
        <AutoScroll />
        <Header />
        <Nav {...content} {...setContent} />
        <Routes>
          <Route path="/" element={<Profile />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/about-me" element={<Profile />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/*" element={<Nothing />} /> */}
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
