import "./Profile.css";


function Profile() {



  return (
    <main>
      <div className="profile-container">
        <div className="section">
          <div className="header">About me</div>
          <div className="bio">
            I am an aspiring full stack developer, with a portfolio of projects
            covering Node, React, PSQL, and basic web development with HTML and
            CSS. My background in aerospace engineering has also helped me develop a
            strong problem-solving skillset, with a technical curiosity that
            drives me to find solutions using experimentation and continuous
            improvement.
          </div>
        </div>
        <div className="section">
          <div className="questions-container">
            <div className="header">Q & A</div>

            <div className="question">I like to work with people who...</div>
            <div className="answer">
              Are open to trying new approaches to problems, and enjoy seeing
              each other succeed
            </div>
            <div className="question">I want to work for a company that...</div>
            <div className="answer">
              Rewards creativity, invests in it's employees, giving them space
              to experiment and learn new skills
            </div>
            <div className="question">In 3-5 years I want to be...</div>
            <div className="answer">
              In a senior tech role, operating at the forefront of innovation
              within my team
            </div>
            <div className="question">Outside of work I...</div>
            <div className="answer">
              Enjoy trying new things, mountain biking, watersports, and working
              on cars
            </div>
          </div>
        </div>
        <div className="section">
          <div className="header">My coding journey</div>
          <div className="coding-journey">
            <div className="seperator" />
            <div className="experience first">
              <img
                className="company-logo"
                src="images/company-logos/uol.png"
                alt="University of Leicester"
              />
              <div className="experience-description">
                <span className="year">2017</span> - Learnt Matlab while
                completing my engineering degree at the University of Leicester
              </div>
            </div>

            <div className="experience middle">
              <img className="company-logo" 
              src="images/company-logos/ci.png"
              alt="Consumer Insight"
               />
              <div className="experience-description">
                <span className="year">2019</span> - Scripted engaging online
                market research surveys while working for Consumer Insight.
                Using askia design, a menu based scripting software, modified
                with snippets of HTML, CSS and JavaScript, I scripted surveys on
                behalf of clients such as Severn Trent, Channel 4 and
                Laithwaites. This was my first experience designing content
                around engaging UX. I went on to use self-taught VBA to automate
                various Excel functions which improved efficiency when
                processing data.
              </div>
            </div>

            <div className="experience middle">
              <img
                className="company-logo"
                src="images/company-logos/codecademy.jpeg"
                alt="Codecademy"
              />
              <div className="experience-description">
                <span className="year">2022</span> - Started learning to code
                using Codecademy, completing 'Learn HTML', 'Learn JavaScript',
                'Learn Intermediate JavaScript' and most importantly sparking my
                passion for software development
              </div>
            </div>

            <div className="experience middle">
              <img
                className="company-logo"
                src="images/company-logos/northcoders.jpeg"
                alt="Northcoders"
              />
              <div className="experience-description">
                <span className="year">2022</span> - Eager to take the next step
                into a software career, I enrolled in the October 2022
                Northcoders bootcamp. Here I learnt many invaluable soft and
                hard skills including: Agile Software Development, Test Driven
                Development, Object Oriented and Functional Programming, Git,
                Software Spiking, Error Handling, React, PSQL, Express, as well
                as further building on my JavaScript, HTML and CSS abilities
              </div>
            </div>
            <div className="experience middle">
              <img
                className="company-logo"
                src="images/company-logos/s2z.png"
                alt="Switch2Zero"
                style={{padding: "4px", boxSizing: "border-box"}}
              />
              <div className="experience-description">
                <span className="year">2023</span> - I joined the Switch2Zero development team in April 2023, and quickly established myself as a reliable problem solver and innovator. I initially focused on backend development, becoming the team go-to for Postgres, Stripe payment integrations and Nest JS development. My capstone achievement is a comprehensive vehicle emissions calculator and offset system, an idea I put forward, and then developed with close collaboration with colleagues from all departments in the business.
              </div>
            </div>
            <div className="experience last">
              <img
                className="company-logo q"
                src="images/company-logos/q.svg"
                alt="Question mark"
              />
              <div className="experience-description">
                <span className="year">2024/09</span> - Your company? I am
                currently looking for my next challenge, in a company where I
                can further develop my skills, learn new coding languages, and
                have a positive impact on my department. If you think this could
                be your company, please check out my portfolio or contact
                details from the navigation bar or buttons below
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Profile;
