function TechItem({ item }) {
  let itemImgUrl;
  switch (item) {
    case "Express":
      itemImgUrl = "images/tech-logos/Express.png";
      break;
    case "PostgreSQL":
      itemImgUrl = "images/tech-logos/postgresql2.png";
      break;
    case "React":
      itemImgUrl = "images/tech-logos/react.svg.png";
      break;
    case "Node":
      itemImgUrl = "images/tech-logos/node.png";
      break;
    case "Jest":
      itemImgUrl = "images/tech-logos/jest.png";
      break;
    case "React Native":
      itemImgUrl = "images/tech-logos/react-native.png";
      break;
    case "Firebase":
      itemImgUrl = "images/tech-logos/firebase.png";
      break;
    case "Socket.io":
      itemImgUrl = "images/tech-logos/socketio.png";
      break;
    case "SvelteKit":
        itemImgUrl = "images/tech-logos/svelte.png"
        break;
    case "Typescript":
      itemImgUrl = "images/tech-logos/typescript.svg"
      break;
    default:
      break;
  }
  return (
    <div className="tech-item">
      <img className={`${item} tech-logo`} src={itemImgUrl} alt={item} />
    </div>
  );
}

export default TechItem;
 