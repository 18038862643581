import "./Portfolio.css";
import PortfolioCard from "../PortfolioCard/PortfolioCard";
import portfolioProjects from "../../constants/portfolioProjects.json"

import { useState } from "react";
import TechItem from "../TechItem/TechItem";

function Portfolio() {
  const [fullImg, setFullImg] = useState(false);
  const [fullImgUrl, setFullImgUrl] = useState("");

  return (
    <main>
      {fullImg && (
        <div className="glass-box" onClick={() => setFullImg(false)}>
          <div className="glass-box-label">Click anywhere to return</div>
          <img className="full-img" src={fullImgUrl} alt="full-screen click anywhere to return"/>
          <div className="glass-box-label">Click anywhere to return</div>
        </div>
      )}
      <div className="tech-index">
        <div className="tech-index-item">
          <TechItem item="Node" />
          <div>Node.js</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="Typescript" />
          <div>Typescript</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="PostgreSQL" />
          <div>PostgreSQL</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="Express" />
          <div>Express.js</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="SvelteKit" />
          <div>SvelteKit</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="React" />
          <div>React</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="React" />
          <div>React Native</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="Jest" />
          <div>Jest</div>
        </div>
        <div className="tech-index-item">
          <TechItem item="Firebase" />
          <div>Firebase</div>
        </div>
      </div>
      <div className="portfolio-container">
        {portfolioProjects && portfolioProjects.map((project) => <PortfolioCard 
        setFullImg={setFullImg}
        setFullImgUrl={setFullImgUrl}
        title={project.title}
        imgUrl={project.imgUrl}
        tech={project.tech}
        githubRepo={project.githupRepoUrl}
        hostedUrl={project.hostedUrl}
        notes={
          project.bulletNotes
        }
        />)}
        
      </div>
    </main>
  );
}

export default Portfolio;
